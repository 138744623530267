<template>
    <b-modal :id="'modal-' + type" :title="modalTitle" hide-footer size="lg" centered>
        <div class="card-body">
            <form>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="mb-3">
                            <label for="name">Nom de la catégorie</label>
                            <input
                                v-model="category.name"
                                type="text"
                                class="form-control"
                                id="name"
                                placeholder="Entrer un nom"
                                name="name"
                            />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-end">
                            <button @click.prevent="createCategory()" class="btn btn-primary" v-if="type == 'add'">
                                Ajouter la catégorie
                                <i class="uil uil-plus ms-2"></i>
                            </button>
                            <button @click.prevent="updateCategory()" class="btn btn-primary" v-else-if="type == 'update'">
                                Modifier la catégorie
                                <i class="uil uil-pen ms-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </b-modal>
</template>

<script>
    import Swal from "sweetalert2";
    import api from '../api/api';
    import Category from '../models/category';
    import Utilities from "../services/utilities";

    export default {
        name: 'ServiceCategoryForm',
        data() {
            return {
                submitted: false,
                errors: [],
                category: new Category(''),
                modalTitle: ''
            }
        },
        props: ['type', 'selectedCategory'],
        mounted() {
            if (this.type == 'update') {
                this.category = this.selectedCategory;
                this.modalTitle = 'Modifier une catégorie';
            } else {
                this.modalTitle = 'Ajouter une catégorie';
            }
        },
        watch: {
            selectedCategory(data) {
                this.category = data;
            }
        },
        methods: {
            categoryValid() {
                this.errors = [];
    
                if (!this.category.name) {
                    this.errors.push('Le nom est obligatoire');
                }
    
                if (this.errors.length == 0) {
                    return true;
                } else {
                    Swal.fire("Erreur", this.errors[0], "error");
                    return false;
                }
            },
            createCategory: function () {
                if (this.categoryValid()) {

                    api
                        .post('/service_categories', this.category)
                        .then(
                            (response) => {
                                if (response && response.status == 201) {
                                    Utilities.successmsg("La catégorie a bien été ajoutée !");
                                    this.category = new Category('');
                                    this.$emit('categories-updated', {type: this.type});
                                }
                            },
                            () => {
                                Utilities.errormsg();
                            }
                        )
                }
            },
            updateCategory: function () {
                if (this.categoryValid()) {
                    let categoryToSend = {...this.category};
                    delete categoryToSend.id,

                    api
                        .patch('/service_categories/' + this.category.id, categoryToSend, {
                            headers: {
                                'Content-Type': 'application/merge-patch+json'
                            }
                        })
                        .then(
                            (response) => {
                                if (response && response.status == 200) {
                                    Utilities.successmsg("La catégorie a bien été modifiée !");
                                    this.category = new Category('');
                                    this.$emit('categories-updated', { type: this.type });
                                }
                            },
                            () => {
                                Utilities.errormsg();
                            }
                        )
                }
            },
        }
    }
</script>

<style lang="scss">
    .input-group > div:first-child {
        width: calc(100% - 40px);
    }

    .input-group-append {
        width: 40px;
    }

    .input-group-text {
        justify-content: center;
    }
</style>