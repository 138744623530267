<template>
  <Layout :show-admin="showAdmin" :show-super-admin="showSuperAdmin" :show-super-sales="showSuperSales">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row flex-xs-column-reverse">
              <div class="col-sm-12 col-md-6 mt-2">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter"
                >
                  <label class="d-inline-flex align-items-center">
                    Recherche
                    <b-form-input
                      v-model="filter"
                      type="search"
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                  <div class="text-end">
                      <b-button variant="primary" v-b-modal.modal-add>
                          Ajouter une catégorie
                          <i class="uil uil-plus ms-2"></i>
                      </b-button>
                      <ServiceCategoryForm @categories-updated="onCategoriesUpdated" :type="'add'" />
                  </div>
              </div>
            </div>
            <div class="table-responsive mb-4 mt-4">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template v-slot:cell(action)="data">
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                            <button class="btn px-2 text-primary" v-b-modal.modal-update content="Modifier" v-tippy @click="onUpdateCategory(data.item)">
                                <i class="uil uil-pen font-size-18"></i>
                            </button>
                        </li>
                        <li class="list-inline-item">
                            <button class="btn px-2 text-danger" content="Supprimer" v-tippy @click="onDeleteCategory(data.item.id)">
                                <i class="uil uil-trash-alt font-size-18"></i>
                            </button>
                        </li>
                    </ul>
                </template>
              </b-table>
              <div class="text-center" v-if="loading">
                  <b-spinner class="align-middle" variant="primary"></b-spinner>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    <span class="pe-2">Afficher</span>
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>
                    <span class="ps-2">entrées</span>
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ServiceCategoryForm @categories-updated="onCategoriesUpdated" :type="'update'" :selected-category="selectedCategory" />
  </Layout>
</template>

<script>
  import Swal from "sweetalert2";
  import Layout from "../layouts/main";
  import PageHeader from "@/components/page-header";
  import ServiceCategoryForm from "@/forms/service-category-form";
  import appConfig from "@/app.config";
  import api from '../../api/api';
  import Category from '../../models/category';
  import Utilities from "../../services/utilities";

  export default {
    page: {
      title: "Catégories de prestation",
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    },
    components: { Layout, PageHeader, ServiceCategoryForm },
    data() {
      return {
        tableData: [],
        title: "Catégories de prestation",
        items: [
          {
            text: "Accueil",
            to: "/"
          },
          {
            text: "Catégories de prestation",
            active: true,
          },
        ],
        selectedCategory: new Category(''),
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "code",
        sortDesc: false,
        loading: true,
        fields: [
          {
            key: "name",
            label: "Nom",
            sortable: true,
          },
          "action",
        ],
      };
    },
    props: ["showAdmin", "showSuperAdmin", "showSuperSales"],
    computed: {
      rows() {
        return this.tableData.length;
      },
    },
    created() {
      if (!this.$props.showAdmin && !this.$props.showSuperSales) {
          this.$router.push('/');
      }
    },
    mounted() {
      this.totalRows = this.items.length;
      this.loadServiceCategories();
    },
    methods: {
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      closeModal(type) {
        this.$bvModal.hide('modal-' + type);
      },
      loadServiceCategories() {
        api
          .get('/service_categories')
          .then(
              (response) => {
                  if (response && response.status == 200) {
                    if (Array.isArray(response.data)) {
                      this.tableData = response.data;
                      this.loading = false;
                    }
                  }
              },
              () => {
                  Utilities.errormsg();
              }
          )
      },
      onUpdateCategory(category) {
        this.selectedCategory = category;
      },
      onCategoriesUpdated(payload) {
        this.closeModal(payload.type);
        this.loadServiceCategories();
      },
      onDeleteCategory(id) {
        Swal.fire({
          title: "Attention",
          text: "Êtes-vous sûr de vouloir supprimer cette catégorie de prestation ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Supprimer",
          cancelButtonText: "Annuler",
        }).then((result) => {
          if (result.value) {
            api
              .delete('/service_categories/' + id)
              .then(
                  (response) => {
                      if (response && response.status == 204) {
                          Swal.fire({
                            title: "Succès",
                            text: "La catégorie a bien été supprimée !",
                            timer: 1500,
                            icon: "success",
                            confirmButtonColor: "#2e58a6",
                            confirmButtonText: "Fermer",
                          });
                          this.loadServiceCategories();
                      }
                  },
                  (error) => {
                      if (error.response.data.detail) {
                          Utilities.alertmsg(error.response.data.detail);
                          return;
                      }
                      Utilities.errormsg();
                  }
              )
          }
        });
      }
    },
    middleware: "authentication",
  };
</script>

<style lang="scss" scoped>
    @media all and (max-width: 576px) {
        .dataTables_filter::v-deep {
            label, input {
                width: 100%;
            }

            label {
                margin-top: 30px;
            }
        }

        .flex-xs-column-reverse {
            flex-direction: column-reverse;
        }
    }

    ::v-deep {
      th, td {
        white-space: nowrap;
      }
    }

    ::v-deep .table.b-table > thead > tr {
        & >[aria-sort] {
            cursor: pointer;
            background-image: none;
            background-repeat: no-repeat;
            background-size: 0.65em 1em;

            &:not(.b-table-sort-icon-left) {
                background-position: right 0.375rem center;
                padding-right: calc(0.75rem + 0.65em);
            }
        }

        & > [aria-sort=descending] {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3E%3Cpath d='M51 101l25-23 24-22H1l25 22z'/%3E%3C/svg%3E");
        }

        & > [aria-sort=ascending] {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath d='M51 1l25 23 24 22H1l25-22z'/%3E%3Cpath opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3E%3C/svg%3E");
        }

        & > [aria-sort=none] {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath opacity='.3' d='M51 1l25 23 24 22H1l25-22zm0 100l25-23 24-22H1l25 22z'/%3E%3C/svg%3E");
        }
    }
</style>